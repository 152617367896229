import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout";
import HeroWithImage from "../../components/heroWithImage/index";
import Team from "../../containers/our-team/Team";
import Seo from "../../components/seo";

import heroDesktop from "../../assets/heroDesktop/Team.png";
import heroMobile from "../../assets/heroMobile/Team.png";

const OurTeam = ({ data }) => {
const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="OUR TEAM" />
      <HeroWithImage
        title={intl.locale === "fr" ? "ÉQUIPE" : "TEAM"}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <Team data={data.team.nodes[0]} members={data.members.edges} />
    </Layout>
  );
};

export const query = graphql`
  query OurTeamQuery($locale: String) {
    members: allContentfulTeamMember(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      edges {
        node {
          name
          image {
            file {
              url
            }
          }
          position
          bioRichText {
            raw
          }
          role
          isBoardOfDirectors
          linkedinLink
          emailLink
        }
      }
    }
    team: allContentfulOurTeamManagementTeam(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        title
        subheading
        heading
      }
    }
  }
`;

export default OurTeam;
